/* Always set the map height explicitly to define the size of the div
			 * element that contains the map. */

#pac-icon,
.pac-icon,
.pac-icon-marker {
	display: none;
}

.pac-item {
	background-color: white;
	padding: 0.2rem;
	font-family: Raleway;
	font-size: 0.875rem;
	z-index: 1000;
}

.pac-item .pac-item-query {
	font-family: Raleway;
	font-size: 1rem;
	padding-left: 0.5rem;
	z-index: 1000;
}
.pac-item:hover {
	background-color: rgb(255, 242, 0);
	z-index: 1000;
}
.pac-container {
	z-index: 10000;
}

#pac-container .pac-logo,
.hdpi::after {
	display: none;
}

#map {
	height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	z-index: 1000;
}

#description {
	font-family: Raleway;
	font-size: 15px;
	font-weight: 300;
}

#infowindow-content .title {
	font-weight: bold;
}

#infowindow-content {
	display: none;
}

#map #infowindow-content {
	display: inline;
}

.pac-card {
	margin: 10px 10px 0 0;
	border-radius: 2px 0 0 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	background-color: rgb(255, 242, 0);
	font-family: Roboto;
	z-index: 1000;
}

#pac-container {
	padding-bottom: 30px;
	margin-right: 32px;
}
.pac-container pac-logo hdpi {
	display: none;
}

.pac-controls {
	display: inline-block;
	padding: 5px 11px;
}

.pac-controls label {
	font-family: Roboto;
	font-size: 13px;
	font-weight: 300;
}

#pac-input {
	background-color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	margin-left: 12px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 400px;
}

#pac-input:focus {
	border-color: #4d90fe;
}

#title {
	color: #fff;
	background-color: #4d90fe;
	font-size: 25px;
	font-weight: 500;
	padding: 6px 12px;
}
