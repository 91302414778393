.react-autosuggest__container {
	position: relative;
	width: 100%;
}

.react-autosuggest__input {
	width: 100%;
	height: 47px;
	padding: 10px 20px;
	border: 0;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	width: 450px;
	margin-left: -31px;
	margin-top: 2px;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

@media only screen and (max-width: 600px) {
	.react-autosuggest__input {
		width: 100%;
		height: 47px;
		padding: .5rem;
		border: 0;
		font-family: Helvetica, sans-serif;
		font-weight: 300;
		font-size: 12px;
	}

	.react-autosuggest__suggestions-container--open {
		display: block;
		position: absolute;
		width: 80vw;
		margin-top: 2px;
		background-color: #fff;
		font-family: Helvetica, sans-serif;
		font-weight: 300;
		font-size: 16px;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 2;
	}
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #fff200;
}

.react-autosuggest__suggestion-match {
	font-weight: bold;
}
