@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("../Assets/Fonts/raleway-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Raleway"), local("Raleway-Regular"),
    url("../Assets/Fonts/raleway-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../Assets/Fonts/raleway-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../Assets/Fonts/raleway-v14-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../Assets/Fonts/raleway-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../Assets/Fonts/raleway-v14-latin-regular.svg#Raleway") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  src: url("../Assets/Fonts/raleway-v14-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
    url("../Assets/Fonts/raleway-v14-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../Assets/Fonts/raleway-v14-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../Assets/Fonts/raleway-v14-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("../Assets/Fonts/raleway-v14-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../Assets/Fonts/raleway-v14-latin-600.svg#Raleway") format("svg"); /* Legacy iOS */
}
